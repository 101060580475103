// import React, { useState } from 'react';
// import { toPng } from 'html-to-image';

// function Popup() {
//   const [imageUrl, setImageUrl] = useState('');

//   const handleConvert = () => {
//     const node = document.getElementById('my-node');
//     toPng(node)
//       .then(function (dataUrl) {
//         setImageUrl(dataUrl);
//         shareOnFacebook(dataUrl);
//       })
//       .catch(function (error) {
//         console.error('Oops, something went wrong!', error);
//       });
//   };

//   const shareOnFacebook = (imageDataUrl) => {
//     if (navigator.share) {
//       navigator
//         .share({
//           text: 'Check out my image!',
//           files: [dataURItoFile(imageDataUrl)],
//         })
//         .then(() => console.log('Shared on Facebook!'))
//         .catch((error) => console.error('Error sharing on Facebook:', error));
//     } else {
//       console.error('Web Share API not supported.');
//     }
//   };

//   const dataURItoFile = (dataURI) => {
//     const byteString = atob(dataURI.split(',')[1]);
//     const mimeType = dataURI.split(',')[0].split(':')[1].split(';')[0];
//     const ab = new ArrayBuffer(byteString.length);
//     const ia = new Uint8Array(ab);
//     for (let i = 0; i < byteString.length; i++) {
//       ia[i] = byteString.charCodeAt(i);
//     }
//     const blob = new Blob([ab], { type: mimeType });
//     const file = new File([blob], 'image.png', { type: mimeType });
//     return file;
//   };

//   return (
//     <div>
//       <div id="my-node">
//         <h1>Hello, world!</h1>
//         <p>This is my HTML content.</p>
//       </div>
//       <button onClick={handleConvert}>Convert to image and share on Facebook</button>
//       {imageUrl && <img src={imageUrl} alt="Converted image" />}
//     </div>
//   );
// }

// export default Popup;








import React, { useState } from 'react';
import { toPng } from 'html-to-image';

function Popup() {
  const [imageUrl, setImageUrl] = useState('');

  const handleConvert = () => {
    const node = document.getElementById('my-node');
    toPng(node)
      .then(function (dataUrl) {
        setImageUrl(dataUrl);
        shareOnFacebook(dataUrl, 'https://example.com');
      })
      .catch(function (error) {
        console.error('Oops, something went wrong!', error);
      });
  };

  const shareOnFacebook = (imageDataUrl, websiteUrl) => {
    if (navigator.share) {
      navigator
        .share({
          text: 'Check out my image and visit my website!',
          url: websiteUrl,
          files: [dataURItoFile(imageDataUrl)],
        })
        .then(() => console.log('Shared on Facebook!'))
        .catch((error) => console.error('Error sharing on Facebook:', error));
    } else {
      console.error('Web Share API not supported.');
    }
  };

  const dataURItoFile = (dataURI) => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeType = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: mimeType });
    const file = new File([blob], 'image.png', { type: mimeType });
    return file;
  };

  return (
    <div>
      <div id="my-node">
        <h1>Hello, world!</h1>
        <p>This is my HTML content.</p>
      </div>
      <button onClick={handleConvert}>Convert to image and share on Facebook</button>
      {imageUrl && <img src={imageUrl} alt="Converted image" />}
    </div>
  );
}

export default Popup;
