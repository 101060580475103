import React, { useEffect, useState ,useRef} from "react";
import html2canvas from 'html2canvas';

import { NavLink, useNavigate } from "react-router-dom";
import Png from "./components/assets/Png.png";
import { HiInformationCircle } from "react-icons/hi";
import Modal from "react-bootstrap/Modal";

const AppHome = () => {
  const elementRef = useRef(null);

  const handleSnapshot = () => {
    html2canvas(elementRef.current).then(canvas => {
      const image = canvas.toDataURL();
      // Share the image on media
      // For example, you can open a new window to share on Twitter
      window.open(`https://facebook.com/intent/tweet?url=&text=&hashtags=&via=&image=${encodeURIComponent(image)}`);
    });
  };
  
  const [datas, setDatas] = useState([]);
  const [shows, setShows] = useState(false);

  const [error, setError] = useState(null);
  const [selectedLang, setSelectedLang] = useState("en");
  const [selectedLangs, setSelectedLangs] = useState("kd");

  const navigate = useNavigate();
  const handleClick = async (event) => {
    try {
      const response = await fetch(
        "https://api.manifesto.nammakarunadu.in/get_issues.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            lang: selectedLang,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("API call failed.");
      }

      const jsonData = await response.json();
      if (jsonData.error) {
        console.log(jsonData.error_message);
        return;
      }

      var result = jsonData.result;
      console.log(result, "result");
      setDatas(result);
      setError(null);
    } catch (error) {
      setError("Error fetching data.");
    }
    setSelectedLang(event.target.value);
    navigate("/homeen", { state: { result } });
  };
  

  useEffect(() => {
    console.log(datas, "data");
  }, [datas]);
  console.warn(datas, "ttt");

  const handleClicks = async (event) => {
    try {
      const response = await fetch(
        "https://api.manifesto.nammakarunadu.in/get_issues.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            lang: selectedLangs,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("API call failed.");
      }

      const jsonData = await response.json();
      if (jsonData.error) {
        console.log(jsonData.error_message);
        return;
      }

      var result = jsonData.result;
      console.log(result, "result");
      setDatas(result);
      setError(null);
    } catch (error) {
      setError("Error fetching data.");
    }
    setSelectedLang(event.target.value);
    navigate("/homeen", { state: { result } });
  };

  useEffect(() => {
    console.log(datas, "data");
  }, [datas]);
  console.warn(datas, "ttt");

  return (
    <>
      <div className="app">
        <div className="app-left">
          <img src={Png} alt="" />
        </div>
        <div className="app-right">
          <h2>
            Raise your <br /> Voice for the Betterment of Bagalkot & <br /> Win
            Prize every week
          </h2>
          <div className="about-app">
            <p className="btn1" onClick={() => setShows(true)}>
              <HiInformationCircle />
              About The Campaign
            </p>
          </div>
          <div className="app-button">
            <NavLink
              to="/homeen"
              className="btn2"
              value={"en"}
              onClick={handleClick}
            >
              English
            </NavLink>
            <NavLink
              to="/popup"
              className="btn3"
            
            >
              ಕನ್ನಡdsssssssss
            </NavLink>
            <NavLink
              to="/homeen"
              className="btn3"
              value={"kd"}
              onClick={handleClicks}
            >
              ಕನ್ನಡ
            </NavLink><div ref={elementRef}>
      </div>
          </div>
        </div>
        <Modal
          show={shows}
          onHide={() => setShows(false)}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
          <div className="terms-condition">
        <ol>
          <li>
            ನಮ್ಮ ಬಾಗಲಕೋಟೆ ನ್ಯೂಸ್, ಬಾಗಲಕೋಟೆಯ ಅಭಿವೃದ್ಧಿಗಾಗಿ ನಿಮಗೊಂದು ಅವಕಾಶ
            ನೀಡುತ್ತಿದೆ. ಜಿಲ್ಲೆಯ ಸಮಸ್ಯೆಗಳು ಪರಿಹಾರಗೊಂಡಾಗ ಮಾತ್ರ ಅಭಿವೃದ್ಧಿ ಸಾಧ್ಯ.
            ಹಾಗಾಗಿ ನಿಮ್ಮ ಸುತ್ತಲಿನ ಸಮಸ್ಯೆಗಳ ಕುರಿತು ದನಿಯೆತ್ತಿ, ನಾವು ಆ ಸಮಸ್ಯೆಗಳನ್ನು
            ಸಂಬಂಧಪಟ್ಟವರಿಗೆ ತಲುಪಿಸುತ್ತೇವೆ.
          </li>
          <li>
            {" "}
            ಈ ಕೆಳಗಿನ ಮೂರು ಸುಲಭ ಹಂತಗಳಲ್ಲಿ ನೀವು ಬಾಗಲಕೋಟೆಯ ಸಮಸ್ಯೆಗಳನ್ನು ತಿಳಿಸಿ.
            ಪ್ರತಿ ವಾರ ಬಹುಮಾನ ಗೆಲ್ಲುವ ಅವಕಾಶ ಪಡೆಯಿರಿ.
          </li>
          <li> ಸ್ಟೆಪ್ 1- ಭಾಷೆಯನ್ನು ಆಯ್ಕೆ ಮಾಡಿ.</li>
          <li> ಸ್ಟೆಪ್ 2- ವಿಷಯವನ್ನು ಆರಿಸಿ.</li>
          <li>
            {" "}
            ಸ್ಟೆಪ್ 3- ನಿಮ್ಮ ವಿಷಯದಡಿಯಲ್ಲಿ ಬರುವ ಸಮಸ್ಯೆಗಳ ಪೈಕಿ ಸೂಕ್ತವಾದುದನ್ನು
            ಸೆಲೆಕ್ಟ್ ಮಾಡಿ. ನಿಮ್ಮ ಸಮಸ್ಯೆ ಆಯ್ಕೆಗಳಲ್ಲಿ ಇಲ್ಲದಿದ್ದರೆ 'write your
            problem' ಆಯ್ಕೆಗೆ ಹೋಗಿ ಅಲ್ಲಿ ಸಮಸ್ಯೆಯನ್ನು ನಮೂದಿಸಿ.
          </li>
          <li>
            ಸ್ಟೆಪ್ 4- ನಿಮ್ಮ ಕುರಿತ ಕೆಲ ಮಾಹಿತಿಯನ್ನು ತುಂಬಿ (ಬಹುಮಾನ ಗೆದ್ದರೆ
            ತಲುಪಿಸುವುದಕ್ಕಾಗಿ)
          </li>
          <li>
            ಗಮನಿಸಿ : ಬಾಗಲಕೋಟೆಯ ಸಮಸ್ತ ಜನತೆಯೂ ನಮಗೆ ಮುಖ್ಯ ಹಾಗೂ ಸಮಾನ. ಹಾಗಾಗಿ ಪ್ರತಿ
            ವಾರ ಲಾಟರಿ ಮೂಲಕ ವಿಜೇತರನ್ನು ಆಯ್ಕೆ ಮಾಡಲಾಗುವುದು
          </li>
        </ol>
        <ol>
          <li>
            Namma Bagalkot is giving you a chance. For the Betterment of
            Bagalkot.
          </li>
          <li>
            Bagalkot will become better only when the problems here will end.
            For this you will have to raise your voice and share the problems of
            Bagalkot. We will Raise these problems to those who are Responsible
            for the same.
          </li>

          <li>
            All you have to do is to follow 3 simple steps and tell the problem
            of Bagalkot and you will get every week a chance to win prizes!{" "}
          </li>
          <li>Step 1 - Select the language of your choice</li>
          <li>Step 2 - Select the subject</li>
          <li>
            {" "}
            Step 3 - Select your preferred problem from the problems entered. If
            your problem is not in our options then go to the "Write your
            problem" option. And then, you write your problem there.
          </li>
          <li>
            Step 4 - You provide a little information about yourself,like your
            address. It's done because if you win, we can deliver the prize to
            you.
          </li>
          <li>
            Note - The views of all Bagalkot residents are important and equally
            significant for us. So every week the person who will win the prize,
            will be selected randomly.
          </li>
        </ol>
      </div>
          </Modal.Body>
        </Modal>
      </div>

      <div></div>
    </>
  );
};

export default AppHome;
