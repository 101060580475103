import React from "react";
import Header from "./Header";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Routes } from "react-router-dom";
import AppHome from "./AppHome.js";
import Footer from "./components/Footer";
import './index.css'
import Homeen from "./components/english/Homeen.js";
import Popup from "./components/Popup";
const App = () => {
  return (
    <>
      <div className="app-background">
        <div className="container">
          <div className="row">
            <Header />
            <Router>
              <Routes>
                <Route path="/" element={<AppHome/>} exact />
                <Route exact path="/homeen" element={<Homeen />} />
                <Route exact path="/popup" element={<Popup />} />

              </Routes>
            </Router>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default App;
