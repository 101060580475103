import React from "react";
import { BsFacebook, BsInstagram, BsTwitter, BsYoutube } from "react-icons/bs";

const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 fl">
              <h1>Namma Karunadu</h1>
              <p>Best News Portal of Karnataka</p>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 fm">
              <ul>
                Contact Us
                <li>
                  info@nammakarunadu.in
                </li>
                <li>
                  164 1st Cross 1st Stage AECS Layout, Sanjaynagar, Bengaluru,
                  Karnataka 560094
                </li>
              </ul>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 fr">
              <ul>
                Terms & Conditions
                <li>@2022, NammaKarunadu All Rights Reserved.</li>
                <li>
                  <div className="foooter-icon">
                    <p>Join Us:</p>
                    <a href="https://m.facebook.com/115411231439560/">
                      {" "}
                      <BsFacebook />
                    </a>
                    <a href="https://www.instagram.com/newsnammakarunadu/">
                      {" "}
                      <BsInstagram />
                    </a>

                    <a href="">
                      {" "}
                      <BsYoutube />
                    </a>

                    <a href="https://twitter.com/KarunaduNews">
                      {" "}
                      <BsTwitter />
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
