import axios from "axios";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { toPng } from "html-to-image";

import Png from "../assets/Png.png";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
const Homeen = () => {
  const location = useLocation();
  const result = location.state?.result || [];

  const [selectedButtons, setSelectedButtons] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [selected, setSelected] = useState([]);
  const [disable, setDisable] = useState(0);

  const [name, setName] = useState(" ");

  // modal&&&&&&&&&&&&&&
  const [show, setShow] = useState(false);
  const [shows, setShows] = useState(false);

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClick = (buttonTex) => {
    const index = selected.indexOf(buttonTex);

    if (index === -1) {
      setSelected([...selected, buttonTex]);
    } else {
      const newSelected = [...selected];
      newSelected.splice(index, 1);
      setSelected(newSelected);
    }

    // if (selectedButtons.length >= 5) return alert("you selected alredy 5");
    if (selectedButtons.includes(buttonTex)) {
      setSelectedButtons(selectedButtons.filter((x) => x !== buttonTex));
    } else {
      setSelectedButtons([...selectedButtons, buttonTex]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (selectedButtons.length === 5) {
      setShowForm(true);
    } else {
      alert("Please select exactly 5 buttons.");
    }
  };
  const handleSubmitdata = (e) => {
    e.preventDefault();
    axios
      .post("https://api.manifesto.nammakarunadu.in/post_data.php", {
        name,
        choices: selectedButtons,
      })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
    // alert(
    //   `The selected buttons are: ${selectedButtons.join(
    //     ", "
    //   )}. The personal details are: ${name}`
    // );
    // console.log(name, "namedata");
    setDisable(true);
    setShow(handleShow);
  };

  const buttonStyle = {
    backgroundColor: disable ? "#999999" : "#eeac0f",
    border: "none",
    color: "white",
    padding: "16px 32px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    fontSize: "16px",
    margin: "4px 2px",
    cursor: disable ? "default" : "pointer",
    opacity: disable ? 0.5 : 1,
  };

  // share on social media code************************ start

  const [imageUrl, setImageUrl] = useState("");

  const handleConvert = () => {
    const node = document.getElementById("my-node");
    toPng(node)
      .then(function (dataUrl) {
        setImageUrl(dataUrl);
        shareOnFacebook(dataUrl, "https://example.com");
      })
      .catch(function (error) {
        console.error("Oops, something went wrong!", error);
      });
  };

  const shareOnFacebook = (imageDataUrl, websiteUrl) => {
    if (navigator.share) {
      navigator
        .share({
          text: "Check out my image and visit my website!",
          url: websiteUrl,
          files: [dataURItoFile(imageDataUrl)],
        })
        .then(() => console.log("Shared on Facebook!"))
        .catch((error) => console.error("Error sharing on Facebook:", error));
    } else {
      console.error("Web Share API not supported.");
    }
  };

  const dataURItoFile = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeType = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: mimeType });
    const file = new File([blob], "image.png", { type: mimeType });
    return file;
  };

  // share on social media code************************ end

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="homeen">
            <div className="homeen-left">
              <img src={Png} width="100%" alt="" />
            </div>
            <div className="homeen-right">
              {!showForm ? (
                <div className="grid">
                  <h1>What topic do you want to raise voice on?</h1>
                  <h6>Please choose five</h6>
                  <form onSubmit={handleSubmit}>
                    <div className="buttons">
                      {result.map((btntxt, index) => {
                        const selectedNumber =
                          selected.indexOf(btntxt.issue) + 1;
                        return (
                          <button
                            className="btn"
                            key={index}
                            type="button"
                            onClick={() => handleClick(btntxt.issue)}
                            style={{
                              backgroundColor: selectedButtons.includes(
                                btntxt.issue
                              )
                                ? "#fe0911"
                                : "#eeac0f",
                            }}
                            id={btntxt.id}
                          >
                            {selectedNumber
                              ? `${selectedNumber}. ${btntxt.issue}`
                              : btntxt.issue}
                          </button>
                        );
                      })}
                    </div>
                    <div className="nbtn">
                      <input type="submit" value="Next >" className="nextbtn" />
                    </div>
                  </form>
                </div>
              ) : (
                <form className="form" onSubmit={handleSubmitdata}>
                  <h2>May the gifts reach you when you are winner!</h2>
                  <p>Share a little information</p>
                  <div className="textfield">
                    <label>Name:</label>
                    <input
                      type="text"
                      name="name"
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>

                  <p className="term" onClick={() => setShows(true)}>
                    Click here to read terms & conditions
                  </p>
                  <div className="terms">
                    <input type="checkbox" required />I have read and agree to
                    the Terms & Conditions *
                  </div>
                  <div className="nbtn">
                    <input
                      type="submit"
                      value="Submit"
                      className="nextbtn"
                      disabled={disable}
                      style={buttonStyle}
                    />
                  </div>
                </form>
              )}
            </div>
          </div>
          <div className="creative">
            <div class="wrapper">
              <a href="#demo-modal">Open Demo Modal</a>
            </div>
            <div id="my-node">
              <div id="demo-modal" class="modal">
                <div class="modal__content">
                  <h1>CSS Only Modal</h1>

                  <p>
                    You can use the :target pseudo-class to create a modals with
                    Zero JavaScript. Enjoy!
                  </p>

                  <a href="#" class="modal__close">
                    &times;
                  </a>
                </div>
              </div>

              <button onClick={handleConvert}>
                Convert to image and share on Facebook
              </button>
            </div>
            <Modal show={show} onHide={handleClose}>
              <Modal.Header
                closeButton
                style={{ backgroundColor: "#fff" }}
              ></Modal.Header>
              <Modal.Body>
                <div className="modals">
                  <div className="modals-left">
                    <img src={Png} width="100%" height="auto" alt="" />
                  </div>
                  <div className="modals-right">
                    <h4>{name}</h4>
                  </div>
                </div>
                <div className="modals-wrapper">
                  <h3>MY Manifesto</h3>
                  <div className="bh6">
                    <h6>For Better Karnataka</h6>
                  </div>
                  <div className="issues">
                    {selectedButtons.map((issues) => {
                      return (
                        <>
                          <p>{issues}</p>
                        </>
                      );
                    })}
                  </div>
                  <a href="www.nammakarunadu.com">www.nammakarunadu.com</a>
                </div>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
          <Modal
            show={shows}
            onHide={() => setShows(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <div className="terms-condition">
                <ol>
                  <b>ಷರತ್ತುಗಳು:</b>
                  <li>ಅಕ್ಟೋಬರ್ 24 ರಿಂದ ಅಭಿಯಾನ ಆರಂಭವಾಗುತ್ತದೆ</li>
                  <li> ಪ್ರತೀ ವಾರ ಮೂರು ಬಹುಮಾನಗಳು</li>
                  <li> ಪ್ರಥಮ: ಫ್ರಿಜ್</li>
                  <li> ದ್ವಿತೀಯ: 32 " ಇಂಚಿನ ಎಲ್ ಇಡಿ ಟಿವಿ</li>
                  <li> ತೃತೀಯ: 3 ಬರ್ನರ್ ನ ಗ್ಯಾಸ್ ಸ್ಟವ್</li>
                  <li>ಪ್ರತೀ ಶನಿವಾರ ಸಂಜೆ ವಿಜೇತರನ್ನು ಘೋಷಿಸಲಾಗುವುದು</li>
                  <li>ಪ್ರತೀ ವಾರ ಬಹುಮಾನಗಳನ್ನು ಗೆಲ್ಲುವ‌ ಅವಕಾಶ</li>
                  <li>ವಿಜೇತರನ್ನು ಲಾಟರಿ ಮೂಲಕ ಆಯ್ಕೆ ಮಾಡಲಾಗುವುದು</li>
                  <li>
                    ಪ್ರತೀ ಭಾನುವಾರದಿಂದ ಶುಕ್ರವಾರದ ವರೆಗೆ ಕಳಿಸುವ ಫೋಟೋ, ವಿಡಿಯೋ,
                    ಮಾಹಿತಿ ಆ ವಾರದ ಸ್ಪರ್ಧೆಗೆ ಅರ್ಹವಾಗುತ್ತವೆ.
                  </li>
                  <li>
                    ಯಾವುದೇ ಮಾಹಿತಿ ತಿರುಚಿದ್ದು ಅಥವಾ ಸುಳ್ಳು ಎಂದು ಕಂಡು ಬಂದಲ್ಲಿ
                    ಸ್ಪರ್ಧಿಯನ್ನು ಅನರ್ಹಗೊಳಿಸಲಾಗುವುದು ಷರತ್ತುಗಳು
                  </li>
                  <li>
                    {" "}
                    <b> ವಿದ್ಯಾರ್ಥಿಗಳಿಗೆ ವಿಶೇಷ ಆಫರ್</b>
                  </li>
                  <li>
                    ಗೆದ್ದಲ್ಲಿ ವಿದ್ಯಾರ್ಥಿಗಳು ತಮ್ಮ ಇಚ್ಛೆಯ ಬಹುಮಾನಗಳನ್ನು
                    ಪಡೆಯಬಹುದು(ಉದಾ: ಮೊಬೈಲ್)
                  </li>
                </ol>
                <ol>
                  <b>Terms and conditions:</b>
                  <li>Campaign starts from 24th October</li>
                  <li>3 prizes in a week</li>

                  <li>1st prize - Refrigerator</li>
                  <li>2nd prize - 32" LED TV</li>
                  <li>3rd prize - 2-3 Burner Gas Stove</li>
                  <li> Prize to be announced EVERY WEEK</li>
                  <li>Prize to be announced every SATURDAY evening</li>
                  <li>
                    {" "}
                    Winners will be selected randomly as we do not discriminate
                    amongst our participants
                  </li>
                  <li>
                    {" "}
                    Participation eligibility from every Sunday to Friday
                  </li>
                  <li>
                    {" "}
                    All applications are liable for disqualification if any
                    discrepancy in information provided is found
                  </li>
                </ol>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default Homeen;
