import React from "react";
import './index.css'
import { AiOutlineHome } from "react-icons/ai";

const Header = () => {
  return (
    <>
      <div className="header">
        <AiOutlineHome />{" "}
        <a href="https://www.manifesto.nammakarunadu.in/">Main Website</a>
      </div>
    </>
  );
};

export default Header;
